export enum SgQueryKey {
  affectedResources = 'affectedResources',
  appSettings = 'appSettings',
  boundaryAlerts = 'boundaryAlerts',
  dataFlowGroups = 'dataFlowGroups',
  dataFlows = 'dataFlows',
  dataFlowClouds = 'dataFlowClouds',
  divisions = 'divisions',
  features = 'features',
  featureComments = 'featureComments',
  featureIntent = 'featureIntent',
  featureOwners = 'featureOwners',
  featureProduct = 'featureProduct',
  featureServices = 'featureServices',
  featureDuplicated = 'featureDuplicated',
  cloudParityReport = 'cloudParityReport',
  cloudParityReportDownload = 'cloudParityReportDownload',
  kpi = 'kpi',
  lookups = 'lookups',
  organizations = 'organizations',
  overviewKpiSummary = 'overviewKpiSummary',
  products = 'products',
  productOwners = 'productOwners',
  resourceGroups = 'resourceGroups',
  resourceProviders = 'resourceProviders',
  resourceProvidersWithTypes = 'resourceProvidersWithTypes',
  resources = 'resources',
  resource = 'resource',
  serviceAffiliations = 'serviceAffiliations',
  serviceDataFlows = 'serviceDataFlows',
  servicesFeatureManagement = 'servicesFeatureManagement',
  services = 'services',
  servicesByProducts = 'servicesByProducts',
  servicePlans = 'servicePlans',
  skus = 'skus',
  skuMappingReport = 'skuMappingReport',
  skuMappingReportDownload = 'skuMappingReportDownload',
  trendKpi = 'trendKpi',
  tmErrors = 'tmErrors',
  tmFileExistence = 'tmFileExistence',
  tmLinkables = 'tmLinkables',
  tmNodes = 'tmNodes',
  tmNodeMetadata = 'tmNodeMetadata',
  users = 'users',
  featureAttributes = 'featureAttributes',
  userById = 'userById',
  diagramNames = 'diagramNames',
  nodeResourcesPreview = 'nodeResourcesPreview',
  userProfile = 'userProfile',
  uncollapseNode = 'uncollapseNode',
  linkableDataFlows = 'linkableDataFlows',
  refreshTime = 'refreshtime',
  configFlags = 'configFlags',
  serviceComponents = 'serviceComponents',
  rti = 'rti',
  rtiTeams = 'rtiTeams',
  rtiEnvironments = 'rtiEnvironments',
  rtiMetadata = 'rtiMetadata',
  aimlEuDashboard = 'aimlEuDashboard',
  rtiVulnerability = 'rtiVulnerability',
  rtiVulnerabilityTeams = 'rtiVulnerabilityTeams',
  rtiVulnerabilityEnvironments = 'rtiVulnerabilityEnvironments',
  rtiVulnerabilityMetadata = 'rtiVulnerabilityMetadata',
  rtiVulnerabilityDownloadReport = 'rtiVulnerabilityDownloadReport',
  sgAlerts = 'sgAlerts',
  sgAlertsDetail = 'sgAlertsDetail',
  activeAlertsTrend = 'activeAlertsTrend',
  falsePositiveRate = 'falsePositiveRate',
  sgiDatasets = 'sgiDatasets',
  sgiDatasetDependencies = 'sgiDatasetDependencies',
  sgiJobsForApp = 'sgiJobsForApp',
  sgiApplicationNames = 'sgiApplicationNames',
  sgiJobEnvironments = 'sgiJobEnvironments',
  sgiJobMetadataForRun = 'sgiJobMetadataForRun',
  sgiDatasetIngestionActivities = 'sgiDatasetIngestionActivities',
  demoEdges = 'demoEdges',
  demoNodes = 'demoNodes',
}
